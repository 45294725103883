@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

:root {
  --font-base: "DM Sans", 'sans-serif';

  --primary-color: #edf2f8;
  --secondary-color: #675fff;
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --gray-color: #6b7688;
  --brown-color: #46364a;
  --white-color: #ffffff;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
.title-head{
  color:#3f3e85;
	font-size:48px;
	line-height:50px;
	font-family: Poppins;
	font-weight: 600;
	margin-bottom: 0.75rem;
}
body {
  font-family: var(--font-base);
  color: var(--black-color);
}

.app__works {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__work-filter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  margin: 4rem 0 2rem -4rem; 
}

.app__work-filter .app__work-filter-item {
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  font-family: 'sans-serif';
  width: 350px;
  justify-content: left;
  background-color: #fff;
  color: #000;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 0.5rem;
}

.app__work-filter .app__work-filter-item:hover {
  background-color: var(--secondary-color);
  color: #fff;
}

@media screen and (min-width: 2000px) {
  .app__work-filter .app__work-filter-item {
    padding: 1rem 2rem;
    border-radius: 0.85rem;
  }
}

.app__work-filter .item-active {
  background-color: var(--secondary-color);
  color: #fff;
}
.app__flex {
  display: flex;
  
}

.app__work-filter {
  flex: 1;
  padding-right: 0.8rem; /* Add padding for separation */
}
.app__work-portfolio {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 3.40rem 0 4.5rem; /* Updated margin */
}

.app__work-portfolio .app__work-item {
    width: 270px;
    flex-direction: column;
    margin: 1rem;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #fff;
    color: #000;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    justify-content: space-between;
  }

  .app__work-portfolio .app__work-item:hover {
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
  }

@media screen and (min-width: 2000px) {
  .app__work-portfolio .app__work-item {
    width: 470px;
    padding: 1.25rem;
    border-radius: 0.75rem;
  }
}

@media screen and (max-width: 800px) {
  .app__work-portfolio{
    justify-content: center; /* Center portfolio items */
    margin: 2rem;
  }
  .app__work-portfolio .app__work-item {
    width: 100%;
    margin: 1rem;
  }
}

.app__work-img {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .app__work-img img {
    width: 100px; /* Adjust the size as needed */
    height: 100px; /* Adjust the size as needed */
    border-radius: 50%;
  }

@media screen and (min-width: 2000px) {
  .app__work-img {
    height: 350px;
  }
}

.app__work-content {
    padding: 0.5rem;
    width: 100%;
    position: relative;
    flex-direction: column;
  }

.app__work-content h4 {
  margin-top: 1rem;
  line-height: 1.5;
}

@media screen and (min-width: 2000px) {
  .app__work-content h4 {
    margin-top: 3rem;
  }
}

.app__work-content .app__work-tag {
  position: absolute;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  background-color: #fff;
  top: -25px;
}

/* Flex container */
.flex {
  display: flex;
}

/* Flex wrap and justify center */
.flex-wrap {
  flex-wrap: wrap;
}

.justify-center {
  justify-content: center;
}

/* Gap between flex items */
.gap-10 > * + * {
  margin-left: 2.5rem;
}

/* Margin top */
.mt-14 {
  margin-top: 3.5rem;
}

/* Individual item width and height */
.w-28 {
  width: 7rem;
}

.h-28 {
  height: 7rem;
}

/* Center the content inside the item */
.text-center {
  text-align: center;
}

/* Text color */
.text-dim {
  color: #888888;
}

/* Ball styles */
.app__work-img .ball-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 880px) {
  
  .app__flex {
    flex-direction: column;
  }
  .app__work-filter-container, .app__work-portfolio-container {
    width: 100%; /* Adjust width to fit mobile screens */
    display: flex;
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
  }

  .app__work-filter {
    width: 100%;
    display: flex;
    justify-content: center; /* Center items within the filter */
    flex-wrap: wrap;
    margin: 4rem 0 2rem;
  }

  .app__work-filter-item {
    display: flex; /* Aligns children (text and icon) in a row */
    align-items: center; /* Centers children vertically */
    justify-content: center; /* Centers children horizontally */
  }

  .app__work-filter-item .fa-caret-down {
    margin-left: 8px; /* Adjust space between text and icon */
    color: var(--secondary-color); /* Adjust as per your color theme */
  }
}



