.btn,
.panel,
.form-control,
.img-thumbnail,
.panel-head ing,
.dropdown-menu,
.panel-group .panel,
.nav-tabs > li > a,
.modal-content,
.navbar-toggler,
.nav-pills > li > a,
.pager li > a,
.pager li > span,
.well,
.alert,
.list-group-item:first-child,
.list-group-item:last-child,
.input-group-addon,
.btn-group-divides > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle),
.btn-group-divides > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child),
.ttr-booking-form .ttr-extra-services li .feature-box-lg,
.ttr-booking-form .ttr-extra-services li.active .feature-box-lg:after,
.widget_tag_cloud a,
.searchform input[type="text"],
.searchform input[type="submit"],
ol.comment-list li.comment .comment-body {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

a,
.ttr-box,
.btn,
.form-control,
.ov-box,
.overlay-icon li a i,
.feature-box-xl,
.feature-box-lg,
.feature-box-md,
.feature-box-sm,
.feature-box-xs,
.navbar-toggler,
.menu-logo,
.menu-links .nav > li > a,
.secondary-menu{
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}
/*== >float-clearfix ==*/
.clearfix:after,
.clearfix:before {
    content: "";
    display: table;
}
.clearfix:after {
    clear: both;
}
/*error for select box*/
.has-error .bootstrap-select .dropdown-toggle,
.has-error .bootstrap-select .dropdown-toggle:hover,
.has-error .bootstrap-select .dropdown-toggle:focus {
    border-color: #a94442 !important;
}
/*succes for select box*/
.has-success .bootstrap-select .dropdown-toggle,
.has-success .bootstrap-select .dropdown-toggle:hover,
.has-success .bootstrap-select .dropdown-toggle:focus {
    border-color: #3c763d !important;
}
button{
	outline:none !important;
	cursor:pointer;
}
label {
    font-weight: 600;
    margin-bottom: 10px;
}
.panel-default,
.panel-default > .panel-heading {
    border-color: #e7ecf1;
    color: inherit;
}
.panel-default > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #e7ecf1;
}
.form-group {
    margin-bottom: 25px;
}
.form-control {
    border-color: #e1e6eb;
    box-shadow: none;
    height: 40px;
    font-size: 13px;
    line-height: 20px;
    padding: 9px 12px;
}
textarea.form-control {
	height:120px;
}
.form-control:focus {
    border-color: #e1e1e1;
    outline: 0;
}
.form-control:focus,
.has-error .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}
.form-control.kv-fileinput-caption {
    height: 39px;
}
.form-group.form-inline .radio,
.form-group.form-inline .checkbox {
    margin-right: 15px;
}
/*input group*/
.input-group {
    width: 100%;
}
.input-group .form-control,
.input-group-addon,
.input-group-btn {
    display: table-cell;
}
.input-group-addon {
    background: #fff;
    border-color: #e1e1e1;
    padding: 6px 14px;
    font-size: 16px;
}
.input-group-addon.font-size-20 {
    font-size: 20px;
}
.input-group-addon.fixed-w {
    text-align: center;
    padding: 6px 0;
    width: 40px;
}
.input-group-addon.v-align-t {
    line-height: 30px;
}
/*select box*/
.dropdown-menu {
    font-size: 13px;
}
.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 100%;
}
.bootstrap-select .dropdown-toggle {
    border: 1px solid #e7ecf1 !important;
    background-color: #fff !important;
    height: 40px;
    font-size: 13px;
    color: #999;
}
.bootstrap-select .dropdown-toggle:after {
    display: none;
}
.bootstrap-select .dropdown-toggle:active,
.bootstrap-select .dropdown-toggle:focus,
.bootstrap-select .dropdown-toggle:hover {
    background-color: #fff !important;
    border: 1px solid #e9e9e9 !important;
    box-shadow: none !important;
    outline: 0px !important;
}
.bootstrap-select:active,
.bootstrap-select:focus {
    border: 0px !important;
    box-shadow: none !important;
    outline: 0px !important;
}
.bootstrap-select.show {
    box-shadow: none !important;
    outline: 0px !important;
}
.bootstrap-select.show .dropdown-toggle {
    box-shadow: none !important;
    outline: 0px !important;
}
.bootstrap-select.show.btn-group .dropdown-menu.inner{
	display:block;
}	
.bootstrap-select div.dropdown-menu {
    border: 1px solid #e9e9e9;
    border-radius: 0;
    box-shadow: none;
    margin-top: -1px;
    padding: 0;
    font-size: 13px;
}
.bootstrap-select div.dropdown-menu ul li {
    padding: 0;
}
.bootstrap-select div.dropdown-menu ul li a {
    padding: 5px 15px;
}
.bootstrap-select div.dropdown-menu ul li a img {
    border-radius: 100%;
    -webkit-border-radius: 100%;
    margin-right: 5px;
}
.bootstrap-select .bs-searchbox .form-control {
    padding: 5px 10px;
    height: 30px;
}
.bootstrap-select .dropdown-menu > li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
	white-space: nowrap;
}
.bootstrap-select .dropdown-menu > li > a:hover {
	background-color:#f2f2f4;
}
/*touchspin input type number*/
.bootstrap-touchspin .input-group-btn-vertical {
    position: relative;
    white-space: nowrap;
    width: 25px;
    vertical-align: middle;
    display: table-cell;
	position:absolute;
	right: 0;
    top: 0;
}
.bootstrap-touchspin .input-group-btn-vertical > .btn {
    display: block;
    float: none;
    width: 100%;
    padding: 0;
    position: relative;
    background: transparent;
    height: 21px;
    text-align: center;
    font-size: 14px;
    line-height: 19px;
    color: #b2b2b2;
}
.bootstrap-touchspin .input-group-btn-vertical > .btn:hover{
	color:var(--primary);
}
.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
    border-radius: 0;
    border-top-right-radius: 0;
}
.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
    margin-top: -2px;
    border-radius: 0;
    border-bottom-right-radius: 0;
}
/* .bootstrap-touchspin .input-group-btn-vertical i {
    position: absolute;
    top: 4px;
    left: 5px;
    font-size: 9px;
    font-weight: normal;
    color: #9fa0a1;
} */
/*rating star*/
.input-rating input[type=radio] {
    display: none;
}
.input-rating input[type=radio] + label {
    display: inline-block;
    margin-left: -4px;
    padding-left: 0px !important;
    padding-right: 0px;
    width: 24px;
}
.input-rating input[type=radio] + label:first-of-type {
    margin-left: 0px;
}
.input-rating input[type=radio] + label:before {
    background-color: transparent;
    border: none;
    color: #ffd925;
    content: "\f006";
    display: inline-block;
    font-family: FontAwesome;
    font-size: 20px;
    -webkit-font-smoothing: antialiased;
    height: 14px;
    line-height: 1;
    margin-left: 0px;
    position: static;
    text-align: center;
    top: 2px;
    transition: none;
    width: 14px;
}
.input-rating input[type=radio]:checked + label:before,
.input-rating input[type=radio] + label.marked:before,
.input-rating input[type=radio] + label.filled:before,
.input-rating input[type=radio] + label.hovered.filled:before {
    content: "\f005";
}
.input-rating input[type=radio] + label.hovered:before {
    content: "\f006";
}
.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
    background-color: var(--primary);
}
/*simple rating list*/

.rating-bx {
    color: #ffc300;
    font-size: 14px;
}
.rating-bx i {
    margin: 0 2px;
}
.rating-container .rating-stars::before {
    text-shadow: none;
}
.rating-container {
    color: #c8c8c8;
}
.rating-container .rating-stars {
    color: #3396d1;
}
blockquote {
    padding:10px 20px 10px 30px;
    font-size: 18px;
    color: #555555;
    margin: 40px 0;
    border-left: none;
    line-height: 30px;
    position: relative;
    background: #fff;
    clear: both;
    border-left: 4px solid var(--primary);
}
blockquote p {
    font-size: 24px;
    color: #000;
    line-height: 40px;
    margin-bottom: 0px;
    font-weight: 500;
}